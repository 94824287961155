import React, { ReactNode, Suspense, useEffect, useMemo, useState, lazy } from 'react'
import './App.css'

import {
    Route,
    Navigate,
    useSearchParams,
    useNavigate,
    useLocation,
    Outlet,
    createBrowserRouter,
    RouterProvider,
    createRoutesFromElements,
    redirect,
    LoaderFunctionArgs,
} from 'react-router-dom'

import axios from 'axios'
import { hashKey, QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider, Spin, ThemeConfig } from 'antd'

import { ErrorPage } from 'pages/ErrorPage/ErrorPage.lazy'
import RolesearchDetail from 'pages/RolesearchDetail/RolesearchDetail.lazy'
import Create from 'pages/RolesearchDetail/pages/Create/Create.lazy'
import ChannelDistribute from 'pages/RolesearchDetail/pages/ChannelDistribute/ChannelDistribute.lazy'
import ChannelMetrics from 'pages/RolesearchDetail/pages/ChannelMetrics/ChannelMetrics.lazy'
import ChannelAnalytics from 'pages/RolesearchDetail/pages/ChannelAnalytics/ChannelAnalytics.lazy'
import Contributors from 'pages/RolesearchDetail/pages/Contributors/Contributors.lazy'
import ChannelInbox from 'pages/RolesearchDetail/pages/ChannelInbox/ChannelInbox.lazy'
import ChannelsTable, {
    ChannelsTable2,
} from 'pages/RolesearchList/pages/ChannelsTable/ChannelsTable.lazy'
import Applicants from 'pages/Applicants/Applicants.lazy'
import UserSettings from 'pages/UserSettings/UserSettings.lazy'
import Settings from 'pages/Settings/Settings.lazy'
import ContentLibrary, {
    VideoDetailWrapper,
    VideoDetails,
} from 'pages/ContentLibrary/ContentLibrary.lazy'
import PublishedContent from 'pages/PublishedContent/PublishedContent.lazy'
import PublishedContentDetails from 'pages/PublishedContent/pages/PublishedContentDetails/PublishedContentDetails.lazy'
import { ContributorsLibrary } from 'pages/ContributorsLibrary/ContributorsLibrary.lazy'
import Prospect from 'pages/Prospect/Prospect.lazy'
// import Campaigns from 'pages/Campaign/Campaign.lazy'
// import { ScheduleModal, EmployeeModal, InboxModal } from 'pages/Campaign/Campaign'
import Collections from 'pages/Collections/Collections.lazy'
import CollectionVideos from 'pages/Collections/pages/CollectionVideos/CollectionVideos.lazy'
import CollectionSettings from 'pages/Collections/pages/CollectionSettings/CollectionSettings.lazy'
import { V2Layout } from 'pages/RecordResponses/RecordResponses.lazy'

import Outbox, {
    OutboxResponseWrapper,
    OutboxInner,
    CRQTargets,
    CandidateDetails,
} from 'pages/Outbox/Outbox.lazy'
import ChannelBuilder from 'pages/RolesearchDetail/pages/ChannelBuilder/ChannelBuilder.lazy'
import PagesBuilder from 'pages/PagesDetail/pages/PagesBuilder/PagesBuilder.lazy'
import PagesDistribute from 'pages/PagesDetail/pages/PagesDistribute/PagesDistribute.lazy'
import PagesMetrics from 'pages/PagesDetail/pages/PagesMetrics/PagesMetrics.lazy'
import PagesDetail from 'pages/PagesDetail/PagesDetail.lazy'
import Content from 'pages/Content/Content.lazy'
import Programs from 'pages/Programs/Programs.lazy'
import ProgramDetails from 'pages/Programs/pages/ProgramDetails/ProgramDetails.lazy'
import CandidatesList from 'pages/Candidates/CandidatesList/CandidatesList.lazy'
import ShowcaseList from 'pages/Showcases/ShowcaseList/ShowcaseList.lazy'
import ShowcaseDetails from 'pages/Showcases/ShowcaseDetails/ShowcaseDetails.lazy'
import PublicShowcaseDisplay from 'pages/Showcases/PublicShowcaseDisplay/PublicShowcaseDisplay.lazy'
import { Videos } from 'pages/Content/Content.lazy'
import { CollectionBuilderModal } from 'pages/Content/Content.lazy'

import SpinnerCentered from 'components/SpinnerCentered'
import { SettingsRoutes } from 'pages/Settings/SettingsRoutes'
import { publicShowcaseQueryArg } from 'services/publicShowcaseQueryArg'
import {
    RoleRepPromptFormTemplate,
    roleRepPromptFormTemplateQueryArg,
} from 'services/role_rep_prompt_service'
import LoginRequired from 'components/LoginRequired/LoginRequired'

import { MainMenuOptions } from 'components/Header/MainMenuOptions'
import { MenuOptionByName } from 'components/Header/Header'
import type { MenuItem } from 'components/Header/Header'

import { useUserInfo, userInfoQueryArg } from 'services/user_service'
import type { UserInfo } from 'services/user_service'
import { useUserIsStaff } from 'services/login_service'

import { showcaseDetailQueryArg } from 'services/showcase_service'
import type { CandidateShowcaseDetails } from 'services/showcase_service'
import type { RoleSearch } from 'services/role_search_service'
import { useRoleRep, useRoleRepQueryArgs } from 'services/role_rep_service'
import type { FeedDetails } from 'services/feed_services'

import { rrrvQuery, useRoleRepResponseVideo } from 'services/role_rep_response_video_service'
import { Base } from 'components/BasePage'

import { StyledModal } from 'components/Modal/StyledModal.lazy'
import { baseQueryClient } from 'baseQueryClient'
import JobPostings from 'pages/JobBoard/pages/JobPostings/JobPostings.lazy'
import { jobPostingQueryArg, JobPosting } from 'services/job_board_job_posting_service'
import JobPostingDetail from 'pages/JobBoard/pages/JobPostings/pages/JobPostingDetail/JobPostingDetail'
import {
    BrowseCandidates,
    CandidateDetails as AsyncJobsCandidateDetails,
} from 'pages/JobBoard/JobBoard.lazy'
import { JBCRQQueryArg } from 'services/JBCRQQueryArg'
import AsyncJobPostingSettings from 'pages/JobBoard/pages/JobPostings/pages/JobPostingDetail/pages/AsyncJobPostingSettings/AsyncJobPostingSettings.lazy'
import { ClientPermissions } from 'services/permission_service'
import AsyncCandidatesHome from 'pages/AsyncCandidates/AsyncCandidatesHome/AsyncCandidatesHome.lazy'
import AsyncCandidatesLibrary from 'pages/AsyncCandidates/pages/AsyncCandidatesLibrary/AsyncCandidatesLibrary.lazy'
import AsyncCandidatesApplications from 'pages/AsyncCandidates/pages/AsyncCandidatesApplications/AsyncCandidatesApplications.lazy'
import { Program, programQueryArg } from 'services/program_service'
import CreateAccount from 'pages/CreateAccount/CreateAccount.lazy'
import AsyncSelfSignupConfirmEmail from 'pages/AsyncSelfSignupConfirmEmail/AsyncSelfSignupConfirmEmail.lazy'
import { CandidateMenuOptions, MenuOption } from 'services/main_menu'
import { CompanyDetails, useCompanyDetails } from 'services/company_service'
import InterviewsList from 'pages/InterviewsList/InterviewsList.lazy'
import ResponsesList from 'pages/ResponsesList/ResponsesList.lazy'
import CollectionsList from 'pages/CollectionsList/CollectionsList.lazy'
import InterviewDetails from 'pages/InterviewDetails/InterviewDetails.lazy'
import InterviewDetailsInbox from 'pages/InterviewDetails/pages/InterviewDetailsInbox/InterviewDetailsInbox.lazy'
import InterviewDetailsQuestions from 'pages/InterviewDetails/pages/InterviewDetailsQuestions/InterviewDetailsQuestions.lazy'
import InterviewDetailsSettings from 'pages/InterviewDetails/pages/InterviewDetailsSettings/InterviewDetailsSettings.lazy'
import InterviewDetailsIntegrations from 'pages/InterviewDetails/pages/InterviewDetailsIntegrations/InterviewDetailsIntegrations.lazy'
import { HeaderBar } from 'components/Header/HeaderBar'
import { ArtifactFilters } from 'services/artifact_service'
import { useLayout202409 } from 'useLayout202409'
import { createGlobalStyle } from 'styled-components'
const ActualApp = lazy(() => import('ActualApp').then(m => ({ default: m.ActualApp })))

declare global {
    interface Window {
        sessionIdentifier?: string
        sessionExpirationTime?: Date
        dataLayer?: any[]
        gtag?: (...a: any[]) => void
        Intercom?: (
            actionName: string,
            eventNameOrBootMetadata?: string | any,
            eventMetadata?: Record<string, any>,
        ) => void
    }
}

const isMenuOption = (o: MenuItem | MenuOption): o is MenuOption => !!(o as MenuOption)?.label

const menuOptionIsAllowed = (
    userInfo: UserInfo,
    isStaff: boolean | undefined,
    menuOption: MenuItem | 'settings' | MenuOption,
    companyDetails: CompanyDetails | undefined,
) => {
    if (menuOption === 'settings') {
        return isStaff || userInfo.permissions.includes(ClientPermissions.CAN_VIEW_SETTINGS)
    } else if (isMenuOption(menuOption)) {
        return menuOption.staffOnly
            ? isStaff ||
                  (menuOption.betaTab &&
                      companyDetails?.settings?.activeBetaTabs?.includes(menuOption.key))
            : true
    } else {
        return !!(
            userInfo.tab_permissions[menuOption.name] ??
            (isStaff ? true : menuOption.defaultVisibility && !menuOption.staffOnly)
        )
    }
}

const ProtectedRoute = ({
    userInfo: _userInfo,
    menuOption,
    children,
}: {
    userInfo?: UserInfo
    menuOption: MenuItem | 'settings' | MenuOption
    children?: JSX.Element
}) => {
    const userInfo = useUserInfo(!_userInfo)['data'] ?? _userInfo
    const { data: companyDetails } = useCompanyDetails()
    const [searchParams] = useSearchParams()
    const isStaff = useUserIsStaff()
    if (!userInfo) {
        return <></>
    }
    if (!companyDetails) {
        return <Spin />
    }
    if (!menuOptionIsAllowed(userInfo, isStaff, menuOption, companyDetails)) {
        const targetPage = MainMenuOptions.find(mo =>
            menuOptionIsAllowed(userInfo, isStaff, mo, companyDetails),
        )
        if (targetPage) {
            return (
                <Navigate
                    to={{ pathname: '/' + targetPage.name, search: searchParams.toString() }}
                    replace
                />
            )
        } else {
            return <>404</>
        }
    }
    return children ? children : <Outlet />
}

const baseStyle = (
    <style>
        {`
                        .ant-checkbox-inner { 
                            border-radius: 2px !important;
                        }
                        // .ant-tag:not(.ant-tag-checkable) {
                        //     background :#fafafa;  /* this was the default from ant 4 */
                        // }
                        *, :after, :before {
                            box-sizing: border-box;
                        }
                        a {
                            color : #0a646b;
                        }

                    `}
    </style>
)
const baseAntTheme: ThemeConfig = {
    token: {
        colorPrimary: '#0a646b',
        borderRadius: 8,
    },
    components: {
        Button: {
            colorLink: '#0a646b',
            colorLinkHover: '#0a646b',
            controlOutline: 'unset',
        },
        Upload: {
            colorBorder: '#0003',
        },
    },
}
const ReactQueryDevtoolsProduction = React.lazy(() =>
    //@ts-ignore
    import('@tanstack/react-query-devtools/build/modern/production.js').then(d => ({
        default: d.ReactQueryDevtools,
    })),
)
const setSpoofDefaults = (searchParams: URLSearchParams) => {
    const spoofCompanyId = searchParams.get('c')
    const spoofUserToken = searchParams.get('u')
    const spoofStaffStatus = searchParams.get('s')
    baseQueryClient.setDefaultOptions({
        queries: {
            staleTime: 15000,
            queryKeyHashFn: queryKey => {
                const arrayKey = Array.isArray(queryKey) ? queryKey : [queryKey]
                return hashKey([
                    spoofCompanyId ?? '',
                    spoofUserToken ?? '',
                    spoofStaffStatus ?? '',
                    ...arrayKey,
                ])
            },
        },
    })
    axios.defaults.params = !!spoofCompanyId ? { c: spoofCompanyId } : {}
    if (spoofCompanyId) {
        axios.defaults.headers.common['x-wt-c'] = spoofCompanyId
    }
    if (!!spoofUserToken) {
        axios.defaults.params.u = spoofUserToken
    }
    if (!!spoofStaffStatus) {
        axios.defaults.params.s = spoofStaffStatus
    }
    axios.defaults.baseURL = import.meta.env.VITE_API_URL_BASE
    axios.defaults.withCredentials = true
    axios.defaults.withXSRFToken = axios.defaults.baseURL ? true : undefined // if the api base url is set (e.g on local, where the ports are different)
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = 'X-CSRFToken'
}
setSpoofDefaults(new URLSearchParams(window.location.search))
const WTQueryClientProvider = ({ children }: { children: ReactNode }) => {
    // const [searchParams] = useSearchParams()
    const searchParams = useMemo(() => new URLSearchParams(window.location.search), [])
    const isStaff = searchParams.get('s') ?? ''
    const spoofCompany = searchParams.get('c') ?? ''
    const spoofUser = searchParams.get('u') ?? ''
    const [showDevtools, setShowDevtools] = useState(false)
    useEffect(() => {
        // @ts-ignore
        window.toggleDevtools = () => setShowDevtools(old => !old)
    }, [])
    const queryClient = useMemo(() => {
        baseQueryClient.setDefaultOptions({
            queries: {
                staleTime: 15000,
                queryKeyHashFn: queryKey => {
                    const arrayKey = Array.isArray(queryKey) ? queryKey : [queryKey]
                    return hashKey([spoofCompany, spoofUser, isStaff, ...arrayKey])
                },
            },
        })
        return baseQueryClient
    }, [isStaff, spoofCompany, spoofUser])
    return (
        <>
            {baseStyle}
            <ConfigProvider theme={baseAntTheme}>
                <QueryClientProvider
                    client={queryClient}
                    children={
                        <>
                            {showDevtools && (
                                <>
                                    <Suspense fallback={null}>
                                        <ReactQueryDevtoolsProduction />
                                    </Suspense>
                                </>
                            )}
                            {children}
                        </>
                    }
                />
            </ConfigProvider>
        </>
    )
}

const ContentWrap = () => {
    const searchParams = useSearchParams()[0]
    const videoDetailToken = searchParams.get('vdt') ?? undefined
    const videoDetailTokenCand = searchParams.get('vdtCand') ?? undefined
    const crqDetailToken = searchParams.get('crqt') ?? undefined
    const crqResponsesToken = searchParams.get('crqrt') ?? undefined

    const video = useRoleRepResponseVideo(videoDetailToken ?? videoDetailTokenCand).data
    const { data: _crq } = useRoleRep(
        crqDetailToken || crqResponsesToken,
        crqDetailToken !== undefined || crqResponsesToken !== undefined,
    ) // userolerep will a little too eagerly find a crq token in a url; we only want this open when the param is specifically set
    const crq = useMemo(
        () => (crqDetailToken || crqResponsesToken ? _crq : undefined),
        [crqDetailToken, crqResponsesToken, _crq],
    )
    const navigate = useNavigate()
    return (
        <>
            {video || crq ? (
                <Suspense>
                    <StyledModal
                        centered
                        // width={850}
                        style={{ minWidth: '1000px' }}
                        styles={{
                            body: {
                                width: '100%',
                                height: '90vh',
                            },
                        }}
                        footer={null}
                        open
                        onCancel={() => {
                            const params = new URLSearchParams(searchParams.toString())
                            params.delete('vdt')
                            params.delete('vdtCand')
                            params.delete('crqt')
                            params.delete('crqrt')
                            navigate({
                                search: params.toString(),
                            })
                        }}
                        closable={false}
                    >
                        {video ? (
                            <VideoDetails
                                video={video}
                                distribute
                                mode={videoDetailToken ? 'contributor' : 'candidate'}
                            />
                        ) : crq && crqDetailToken ? (
                            <CandidateDetails />
                        ) : crq && crqResponsesToken ? (
                            <OutboxResponseWrapper />
                        ) : null}
                    </StyledModal>
                </Suspense>
            ) : null}

            <Outlet />
        </>
    )
}
const NavigateWithSearch = (props: React.ComponentProps<typeof Navigate>) => {
    // navigate but just preserve the search string if it's explicit
    const location = useLocation()
    const newProps = { ...props }
    if (!props.to.search?.length) {
        if (typeof props.to === 'string') {
            newProps.to = { pathname: props.to, search: location.search }
        } else {
            newProps.to = { ...props.to, search: location.search }
        }
    }
    return <Navigate {...newProps} />
}
const promptFormRoutes = (mode: 'candidate' | 'contributor') => (
    <>
        <Route
            path="forms"
            handle={{ tabName: 'forms', crumb: () => 'Forms' }}
            // element={<RoleRepPromptSection mode="candidate" />}
            children={
                <>
                    <Route
                        index
                        lazy={() =>
                            import(
                                'pages/RolesearchDetail/pages/RoleRepPromptSection/RoleRepPromptSection'
                            ).then(m => ({
                                element: <m.PromptFormTable mode={mode} />,
                            }))
                        }
                    />
                    <Route
                        path=":rrpfToken/"
                        handle={{
                            headerBar: {
                                useCrumbs: true,
                            },
                            crumb: (f: RoleRepPromptFormTemplate) => f?.name,
                        }}
                        loader={async a => {
                            return (
                                await baseQueryClient.ensureQueryData(
                                    roleRepPromptFormTemplateQueryArg(
                                        mode,
                                        a.params?.rrpfToken ?? '',
                                        baseQueryClient,
                                    ),
                                )
                            ).find(f => f.token === a.params?.rrpfToken)
                        }}
                        lazy={() =>
                            import(
                                'pages/RolesearchDetail/pages/RoleRepPromptSection/RoleRepPromptSection'
                            ).then(m => ({
                                element: <m.PromptFormDetail mode={mode} />,
                            }))
                        }
                        children={
                            <Route
                                path=":qToken?"
                                element={<></>}
                            />
                        }
                    />
                </>
            }
        />
    </>
)
const OutboxRoutes = (
    <>
        <Route
            path="requests"
            handle={{ tabName: 'requests' }}
            element={<OutboxInner mode="contributor" />}
        >
            <Route path=":crqToken/*">
                <Route
                    path="responses"
                    element={<OutboxResponseWrapper />}
                />
                <Route
                    path="targets"
                    element={<CRQTargets />}
                />
            </Route>
        </Route>
        {/* <Route
            path="forms"
            handle={{ tabName: 'forms' }}
            element={<RoleRepPromptSection mode="contributor" />}
        /> */}
        {promptFormRoutes('contributor')}

        <Route
            index
            element={
                <NavigateWithSearch
                    to={{ pathname: 'requests' }}
                    replace
                />
            }
        />
    </>
)

const StaffOnlyRoute = ({ children }: { children?: JSX.Element }) => {
    const isStaff = useUserIsStaff()
    if (!isStaff) {
        return <Navigate to={{ pathname: '/', search: window.location.search }} />
    }
    return children || null
}

const BasePageRoute = () => {
    const { data: companyDetails, isPending } = useCompanyDetails()
    const { value: layout202409 } = useLayout202409()
    const navigate = useNavigate()
    useEffect(() => {
        if (isPending || companyDetails === undefined) {
            return undefined
        }
        if (layout202409) {
            navigate(`/home${window.location.search}`, { replace: true })
        } else {
            navigate(`/ccontent${window.location.search}`, { replace: true })
        }
    }, [companyDetails, isPending, layout202409, navigate])
    return <Spin />
}

const mainRoutes = (
    <>
        <Route
            path="/user_settings/*"
            element={<UserSettings />}
        />
        <Route
            path="/"
            Component={ContentWrap}
            loader={({ request }) => {
                const url = new URL(request.url)
                const videoToken = url.searchParams.get('vdt') ?? url.searchParams.get('vdtCand')
                const crqToken = url.searchParams.get('crqt') ?? url.searchParams.get('crqrt')
                if (videoToken) {
                    baseQueryClient.ensureQueryData(rrrvQuery(videoToken))
                } else if (crqToken) {
                    baseQueryClient.ensureQueryData(
                        useRoleRepQueryArgs(crqToken, !!(crqToken ?? false)),
                    )
                }
                return null
            }}
        >
            <Route
                path="/home"
                lazy={() =>
                    import('Home').then(m => ({
                        element: <m.Home />,
                    }))
                }
            >
                <Route path="interviews">
                    <Route
                        path=":rrpftToken"
                        element={<InterviewDetails />}
                    >
                        <Route
                            path="editor"
                            element={<InterviewDetailsQuestions />}
                        />
                        <Route
                            path="inbox"
                            element={<InterviewDetailsInbox />}
                        />
                        <Route
                            path="settings"
                            element={<InterviewDetailsSettings />}
                        />
                        <Route
                            path="integrations"
                            element={<InterviewDetailsIntegrations />}
                        />
                    </Route>
                    <Route
                        path=":rrpftToken"
                        element={
                            <Navigate to={{ pathname: 'editor', search: window.location.search }} />
                        }
                    />
                    <Route
                        index
                        element={
                            <InterviewsList
                                mode="candidate"
                                pageTitle="Interviews"
                            />
                        }
                    />
                </Route>
                <Route
                    path="responses"
                    element={<ResponsesList pageTitle="Responses" />}
                />
                <Route
                    path="collections"
                    element={
                        <CollectionsList
                            forcedArtifactType={
                                ['videoPostings', 'channels'] as ArtifactFilters['artifactType']
                            }
                        />
                    }
                />
                <Route
                    path="collections/publishable/:channelToken"
                    lazy={() =>
                        import('pages/Content/collectionBuilderQueryArg').then(m => ({
                            loader: async a => {
                                const q = m.collectionBuilderQueryArg(a.params.channelToken)
                                return await baseQueryClient.ensureQueryData<
                                    FeedDetails | RoleSearch
                                >(q)
                            },
                        }))
                    }
                    handle={{
                        headerBar: {
                            mode: null,
                            useCrumbs: true,
                            subMenu: {
                                subMenuItems: [
                                    { key: 'build' },
                                    { key: 'distribute' },
                                    { key: 'metrics', staffOnly: true },
                                ],
                            },
                        },
                        crumb: (ld: FeedDetails | RoleSearch) => ld.name,
                    }}
                >
                    <Route
                        path=":tab"
                        element={
                            <div style={{ marginTop: '-16px' }}>
                                <HeaderBar mode={null} />
                                <CollectionBuilderModal />
                            </div>
                        }
                    />
                    <Route
                        index
                        element={
                            <NavigateWithSearch
                                to={{ pathname: 'build' }}
                                replace
                            />
                        }
                    />
                </Route>
                <Route
                    path="shortlists"
                    element={
                        <CollectionsList
                            forcedArtifactType={['showcases'] as ArtifactFilters['artifactType']}
                        />
                    }
                />
                <Route
                    path="videos"
                    element={<Videos />}
                />
                <Route
                    path="request-forms"
                    element={
                        <InterviewsList
                            mode="contributor"
                            pageTitle="Request Forms"
                        />
                    }
                />
                <Route
                    path="collections/reviewable/:showcaseToken"
                    loader={async a => {
                        return await baseQueryClient.ensureQueryData(
                            showcaseDetailQueryArg(a.params.showcaseToken),
                        )
                    }}
                    handle={{
                        crumb: (ld: CandidateShowcaseDetails) => ld.name,
                        headerBar: {
                            mode: null,
                            useCrumbs: true,
                            subMenu: {
                                subMenuItems: [
                                    { key: 'build' },
                                    { key: 'metrics', staffOnly: true },
                                ],
                            },
                        },
                    }}
                >
                    <Route
                        path=":tab"
                        element={
                            <div style={{ marginTop: '-16px' }}>
                                <HeaderBar mode={null} />
                                <ShowcaseDetails />
                            </div>
                        }
                    />
                    <Route
                        index
                        element={
                            <NavigateWithSearch
                                to={{ pathname: 'build' }}
                                replace
                            />
                        }
                    />
                </Route>

                <Route
                    index
                    loader={async () => redirect(`interviews${window.location.search}`)}
                />
            </Route>

            <Route
                path="/rolesearch-edit/:token/*"
                element={<RolesearchDetail />}
            >
                <Route
                    path="builder"
                    element={<ChannelBuilder />}
                />
                <Route
                    path="distribute"
                    element={<ChannelDistribute />}
                />
                <Route
                    path="metrics"
                    element={<ChannelMetrics />}
                />
                <Route
                    path="*"
                    element={
                        <NavigateWithSearch
                            to={'builder'}
                            replace
                        />
                    }
                />
            </Route>
            <Route
                path="/rolesearch-details/:token/*"
                element={<ChannelsTable />}
            >
                <Route
                    path="analytics"
                    element={<ChannelAnalytics />}
                />
                <Route
                    path="contributors"
                    element={<Contributors />}
                />
                <Route
                    path="inbox"
                    element={<ChannelInbox />}
                />
                <Route
                    path="create/*"
                    element={<Create />}
                ></Route>
                <Route
                    path="*"
                    element={
                        <NavigateWithSearch
                            replace
                            to={'inbox'}
                        />
                    }
                />
            </Route>
            <Route
                index
                element={<BasePageRoute />}
            />

            <Route
                path="jobs"
                element={<Programs prefilters={{ candidates: true }} />}
            />
            <Route
                path="jobs/:programToken/channels/:channelToken"
                element={<CollectionBuilderModal />}
            />
            <Route
                path="jobs/:programToken/:tabName"
                element={<ProgramDetails />}
            />
            <Route
                path="jobs/:programToken"
                element={<ProgramDetails />}
            />

            <Route
                path="projects"
                handle={{
                    crumb: () => 'Projects',
                    headerBar: {
                        label: 'Projects',
                    },
                }}
                children={
                    <>
                        <Route
                            lazy={() =>
                                import('pages/Candidates/ContentWrapperPage').then(m => ({
                                    Component: m.ContentWrapperPage,
                                }))
                            }
                            children={
                                // too bad contentwrapper page doesn't accept children in lieu of outlet
                                <Route
                                    index
                                    element={<Programs prefilters={{ candidates: false }} />}
                                />
                            }
                        />
                        <Route
                            path=":programToken"
                            loader={async a => {
                                return await baseQueryClient.ensureQueryData(
                                    programQueryArg(a.params.programToken),
                                )
                            }}
                            handle={{
                                crumb: (ld: Program) => ld.name,
                                headerBar: {
                                    useCrumbs: true,
                                },
                            }}
                            children={
                                <>
                                    <Route
                                        path="requests/"
                                        handle={{ tabName: 'requests' }}
                                        element={<ProgramDetails />}
                                    >
                                        <Route path=":crqToken/*">
                                            <Route
                                                path="responses"
                                                element={<OutboxResponseWrapper />}
                                            />
                                            <Route
                                                path="targets"
                                                element={<CRQTargets />}
                                            />
                                        </Route>
                                    </Route>
                                    <Route
                                        path=":tabName?"
                                        element={<ProgramDetails />}
                                    />
                                    <Route
                                        path="channels/:channelToken"
                                        element={<CollectionBuilderModal />}
                                    />
                                </>
                            }
                        />
                    </>
                }
            />

            {/* {<Route path="channels" element={<ChannelsTable />} />} */}
            <Route
                path="channels"
                element={
                    <ProtectedRoute menuOption={MenuOptionByName.channels}>
                        <ChannelsTable />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/outbox"
                element={<Outbox mode="contributor" />}
            >
                {OutboxRoutes}
            </Route>
            <Route
                path="/pages-detail/:token/*"
                element={<PagesDetail />}
            >
                <Route
                    path="builder"
                    element={<PagesBuilder />}
                />
                <Route
                    path="distribute"
                    element={<PagesDistribute />}
                />
                <Route
                    path="metrics"
                    element={<PagesMetrics />}
                />
            </Route>
            <Route
                path="/collections/:token/*"
                element={<Collections />}
            >
                <Route
                    path="settings"
                    element={<CollectionSettings />}
                />
                <Route
                    path="videos"
                    element={<CollectionVideos />}
                />

                <Route
                    path="*"
                    element={
                        <NavigateWithSearch
                            to={{
                                pathname: '/collections',
                            }}
                            replace
                        />
                    }
                />
            </Route>

            {/*                    <Route
                path="/candidates/:projectToken/*"
                element={<div>ok</div>}
            >
                <Route
                    path=":candidateToken"
                    element={<div>ok</div>}
                />
            </Route>*/}

            <Route
                path="/ado"
                lazy={() =>
                    import('./pages/JobBoard/PublicPosting/NotFound404').then(m => ({
                        Component: m.NotFound404, // TODO;  maybe explain their permissions? or maybe I guess we just have a more general user profile screen that we can direct to (that shows a read-only view of your permissions? and your email/password reset stuff?)
                    }))
                }
            />
            <Route
                path="/candidates/*"
                element={<CandidatesList />}
                handle={{ headerBar: { mode: 'Candidates' } }}
                loader={async ({ params }) => {
                    const user = await baseQueryClient.ensureQueryData(userInfoQueryArg())
                    if (user.settings?.interviewPermissions?.mode === 'ado' && !params.crqToken) {
                        return redirect(`/ado/${window.location.search}`)
                    }
                    return null
                }}
            >
                <Route
                    path="applicants"
                    element={<ProtectedRoute menuOption={MenuOptionByName.applicants} />}
                >
                    <Route
                        path=":candidateToken?"
                        element={<Applicants />}
                    />
                </Route>

                <Route
                    path="list"
                    element={
                        <ProtectedRoute
                            menuOption={
                                CandidateMenuOptions.find(
                                    mo => mo.key === 'candidates/list',
                                ) as MenuOption
                            }
                        >
                            <Outbox mode="candidate" />
                        </ProtectedRoute>
                    }
                    handle={{
                        headerBar: {
                            label: 'Interviews',
                            subMenu: {
                                subMenuItems: [
                                    { key: 'forms' },
                                    {
                                        key: 'requests',
                                        label: 'Interviews',
                                        staffOnly: true,
                                    },
                                ],
                            },
                        },
                    }}
                >
                    <Route
                        path="requests"
                        handle={{ tabName: 'requests' }}
                        element={
                            <ProtectedRoute
                                menuOption={
                                    CandidateMenuOptions.find(
                                        mo => mo.key === 'candidates/list',
                                    ) as MenuOption
                                }
                            >
                                <OutboxInner mode="candidate" />
                            </ProtectedRoute>
                        }
                    >
                        <Route
                            path=":crqToken/responses"
                            element={<OutboxResponseWrapper mode="candidate" />}
                        />
                        <Route
                            path=":token" // video detail token ; this is here for path hierarchy reasons, but is handled by the candidateslist component, and generally should probably be moved over to using a keyed searchparam instead of a pathparam
                            element={<Outbox mode="candidate" />}
                            handle={{ tabName: 'requests' }}
                        />
                    </Route>
                    {promptFormRoutes('candidate')}

                    <Route
                        path="*"
                        element={
                            <NavigateWithSearch
                                to={{ pathname: 'forms' }}
                                replace
                            />
                        }
                    />
                </Route>

                <Route
                    path="showcases"
                    handle={{
                        crumb: () => 'Shortlists',
                        headerBar: { label: 'Shortlists' },
                    }}
                >
                    <Route
                        index
                        element={
                            <ProtectedRoute
                                menuOption={
                                    CandidateMenuOptions.find(
                                        mo => mo.key === 'candidates/showcases',
                                    ) as MenuOption
                                }
                            >
                                <ShowcaseList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path=":showcaseToken"
                        loader={async a => {
                            return await baseQueryClient.ensureQueryData(
                                showcaseDetailQueryArg(a.params.showcaseToken),
                            )
                        }}
                        handle={{
                            crumb: (ld: CandidateShowcaseDetails) => ld.name,
                            headerBar: {
                                useCrumbs: true,
                                subMenu: {
                                    subMenuItems: [
                                        { key: 'build' },
                                        { key: 'metrics', staffOnly: true },
                                    ],
                                },
                            },
                        }}
                    >
                        <Route
                            path=":tab"
                            element={
                                <ProtectedRoute
                                    menuOption={
                                        CandidateMenuOptions.find(
                                            mo => mo.key === 'candidates/list',
                                        ) as MenuOption
                                    }
                                >
                                    <ShowcaseDetails />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            index
                            element={
                                <NavigateWithSearch
                                    to={{ pathname: 'build' }}
                                    replace
                                />
                            }
                        />
                    </Route>
                </Route>
                <Route
                    path="applicants2"
                    handle={{ crumb: () => 'Responses', headerBar: { label: 'Responses' } }}
                    loader={async ({ params }) => {
                        return {
                            mode: 'applicants',
                            fixedFilters: {},
                        }
                    }}
                    element={
                        <ProtectedRoute
                            menuOption={
                                CandidateMenuOptions.find(
                                    mo => mo.key === 'candidates/applicants2',
                                ) as MenuOption
                            }
                        >
                            <BrowseCandidates productMode="async" />
                        </ProtectedRoute>
                    }
                >
                    <Route
                        loader={async ({ params }) => {
                            return await baseQueryClient.ensureQueryData(
                                JBCRQQueryArg('async', params.crqToken),
                            )
                        }}
                        path=":crqToken"
                        element={<AsyncJobsCandidateDetails productMode="async" />}
                    />
                </Route>
                <Route
                    path="jobs"
                    handle={{
                        crumb: () => 'Jobs',
                        headerBar: { label: 'Jobs' },
                    }}
                >
                    <Route
                        index
                        element={
                            <ProtectedRoute
                                menuOption={
                                    CandidateMenuOptions.find(
                                        mo => mo.key === 'candidates/jobs',
                                    ) as MenuOption
                                }
                            >
                                <JobPostings mode="async" />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path=":postingToken"
                        loader={async ({ params }) => {
                            try {
                                return await baseQueryClient.ensureQueryData(
                                    jobPostingQueryArg('async', params.postingToken),
                                )
                            } catch {
                                return redirect('/candidates/jobs/' + window.location.search)
                            }
                        }}
                        handle={{
                            crumb: (ld: JobPosting) => ld.name,
                            headerBar: {
                                useCrumbs: true,
                                subMenu: {
                                    subMenuItems: [{ key: 'applicants' }, { key: 'settings' }],
                                },
                            },
                        }}
                    >
                        <Route
                            element={
                                <ProtectedRoute
                                    menuOption={
                                        CandidateMenuOptions.find(
                                            mo => mo.key === 'candidates/jobs',
                                        ) as MenuOption
                                    }
                                >
                                    <JobPostingDetail mode="async" />
                                </ProtectedRoute>
                            }
                        >
                            <Route
                                loader={async ({ params }) => {
                                    return await baseQueryClient.ensureQueryData(
                                        jobPostingQueryArg('async', params.postingToken),
                                    )
                                }}
                                path="settings"
                                element={<AsyncJobPostingSettings />}
                            />
                            <Route
                                path="applicants"
                                loader={async ({ params }) => {
                                    return {
                                        mode: 'applicants',
                                        fixedFilters: {
                                            job: params.postingToken,
                                            isApplicantTo: [params.postingToken],
                                        },
                                    }
                                }}
                                element={<BrowseCandidates productMode="async"></BrowseCandidates>}
                            >
                                <Route
                                    loader={async ({ params }) => {
                                        return await baseQueryClient.ensureQueryData(
                                            JBCRQQueryArg('async', params.crqToken),
                                        )
                                    }}
                                    path=":crqToken"
                                    element={<AsyncJobsCandidateDetails productMode="async" />}
                                />
                            </Route>
                            <Route
                                path="*"
                                element={
                                    <NavigateWithSearch
                                        to={{ pathname: 'list' }}
                                        replace
                                    />
                                }
                            />
                        </Route>
                    </Route>
                </Route>
                <Route
                    path="*"
                    element={
                        <NavigateWithSearch
                            to={{ pathname: 'list' }}
                            replace
                        />
                    }
                />
            </Route>
            <Route
                path="/ccontent"
                handle={{
                    headerBar: { mode: 'Contributors' },
                }}
                lazy={() =>
                    import('pages/Candidates/ContentWrapperPage').then(m => ({
                        Component: m.ContentWrapperPage,
                    }))
                }
            >
                <Route
                    index
                    element={
                        <NavigateWithSearch
                            replace
                            to={{ pathname: 'requests' }}
                        />
                    }
                />
                <Route
                    path="requests"
                    element={<Outbox mode="contributor" />}
                    handle={{
                        headerBar: {
                            label: 'Requests',
                            subMenu: {
                                subMenuItems: [{ key: 'requests' }, { key: 'forms' }],
                            },
                        },
                    }}
                >
                    {OutboxRoutes}
                </Route>
                <Route
                    path="library"
                    handle={{
                        headerBar: {
                            label: 'Videos',
                            subMenu: {
                                subMenuItems: [
                                    {
                                        key: 'main',
                                        label: 'Responses',
                                    },
                                    { key: 'clips' },
                                    { key: 'archived' },
                                ],
                            },
                        },
                    }}
                >
                    <Route
                        path=":tab/:token?/*"
                        element={<Videos />}
                    />
                    <Route
                        path="*"
                        index
                        element={
                            <NavigateWithSearch
                                to={{ pathname: 'main' }}
                                replace
                            />
                        }
                    />
                </Route>
                {(['videoPostings', 'channels'] as const).map(channelType => (
                    <Route
                        key={channelType}
                        path={channelType}
                        element={<ChannelsTable2 mode={channelType} />}
                        handle={{
                            headerBar: {
                                label: channelType === 'channels' ? 'Channels' : 'Video Postings',
                            },
                            crumb: () =>
                                channelType === 'channels' ? 'Channels' : 'Video Postings',
                        }}
                    >
                        <Route
                            path=":channelToken"
                            lazy={() =>
                                import('pages/Content/collectionBuilderQueryArg').then(m => ({
                                    loader: async a => {
                                        const q = m.collectionBuilderQueryArg(a.params.channelToken)
                                        return await baseQueryClient.ensureQueryData<
                                            FeedDetails | RoleSearch
                                        >(q)
                                    },
                                }))
                            }
                            handle={{
                                headerBar: {
                                    useCrumbs: true,
                                    subMenu: {
                                        subMenuItems: [
                                            { key: 'build' },
                                            { key: 'distribute' },
                                            { key: 'metrics' },
                                        ],
                                    },
                                },
                                crumb: (ld: FeedDetails | RoleSearch) => ld.name,
                            }}
                        >
                            <Route
                                path=":tab"
                                element={<CollectionBuilderModal />}
                            />
                            <Route
                                index
                                element={
                                    <NavigateWithSearch
                                        to={{ pathname: 'build' }}
                                        replace
                                    />
                                }
                            />
                        </Route>
                    </Route>
                ))}
                <Route
                    path="*"
                    element={
                        <NavigateWithSearch
                            to={{ pathname: '../requests' }}
                            replace
                        />
                    }
                />
            </Route>

            <Route
                path="/collections"
                element={
                    <ProtectedRoute menuOption={MenuOptionByName.collections}>
                        <Collections />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/library/:token/*"
                element={<ContentLibrary />}
            >
                <Route
                    path="details"
                    element={<VideoDetailWrapper mode="contributor" />}
                />
                <Route
                    path="*"
                    element={
                        <NavigateWithSearch
                            replace
                            to={{
                                pathname: '/library',
                            }}
                        />
                    }
                />
            </Route>
            <Route
                path="/library"
                element={
                    <ProtectedRoute menuOption={MenuOptionByName.library}>
                        <ContentLibrary />
                    </ProtectedRoute>
                }
            />

            <Route
                path="analytics"
                lazy={() =>
                    import('pages/CompanyAnalytics/CompanyAnalytics').then(m => ({
                        loader: m.companyAnalyticsLoader(baseQueryClient),
                        Component: m.default,
                    }))
                }
            />
            <Route
                path="/share_popout"
                element={<Prospect />}
            />
            <Route
                path="/artifacts/:token/*"
                element={<PublishedContent />}
            >
                <Route
                    path="details"
                    element={<PublishedContentDetails tab="details" />}
                />
                <Route
                    path="links"
                    element={<PublishedContentDetails tab="links" />}
                />
                <Route
                    path="metrics"
                    element={<PublishedContentDetails tab="metrics" />}
                />
                <Route
                    path="*"
                    element={
                        <NavigateWithSearch
                            replace
                            to={{
                                pathname: '/artifacts',
                            }}
                        />
                    }
                />
            </Route>
            <Route
                path="/artifacts"
                element={<PublishedContent />}
            />
            <Route
                path="/content/:tab/*"
                element={<Content />}
            >
                <Route
                    path="*"
                    element={<Content />}
                />
            </Route>
            <Route
                path="/content"
                element={
                    <NavigateWithSearch
                        to={{
                            pathname: '/content/videos',
                        }}
                    />
                }
            />

            <Route
                path="/contributors/:token/*"
                element={
                    <ProtectedRoute
                        menuOption={{
                            name: 'contributors',
                            displayName: 'Contributors',
                            staffOnly: false,
                            defaultVisibility: true,
                        }}
                    >
                        <ContributorsLibrary />
                    </ProtectedRoute>
                }
            >
                <Route
                    path="details"
                    element={<VideoDetailWrapper mode="contributor" />}
                />
                <Route
                    path="*"
                    element={
                        <NavigateWithSearch
                            replace
                            to={{
                                pathname: '/contributors',
                            }}
                        />
                    }
                />
            </Route>

            <Route
                path="contributors"
                element={
                    <ProtectedRoute
                        menuOption={{
                            name: 'contributors',
                            displayName: 'Contributors',
                            staffOnly: false,
                            defaultVisibility: true,
                        }}
                    >
                        <ContributorsLibrary />
                    </ProtectedRoute>
                }
            />

            <Route
                path="applicants"
                element={<ProtectedRoute menuOption={MenuOptionByName.applicants} />}
            >
                <Route
                    path=":candidateToken?"
                    loader={a =>
                        redirect(
                            `/candidates/applicants/${a.params.candidateToken ?? ''}${
                                window.location.search
                            }`,
                        )
                    }
                />
            </Route>
            <Route
                path="*"
                element={
                    <NavigateWithSearch
                        replace
                        to={{ pathname: '/home' }}
                    />
                }
            />
        </Route>
    </>
)
const allRoutes = [
    {
        path: '/record_responses/get_started',
        element: <V2Layout signup />,
    },
    // TODO delete the now duplicate v2_start routes early 2024, in case any links have been sent out.  Probably also change the name of V2Layout back to RecordResponse - DP
    {
        path: '/candidate_showcases_p/:token',
        loader: async (a: LoaderFunctionArgs) =>
            baseQueryClient.ensureQueryData(publicShowcaseQueryArg(a.params.token)),
        Component: PublicShowcaseDisplay,
        children: [
            {
                path: ':crqToken',
                lazy: () =>
                    import('pages/Showcases/PublicShowcaseDisplay/PublicShowcaseDisplay').then(
                        m => ({
                            Component: m.PublicShowcaseV2CandidateDetails,
                        }),
                    ),
            },
        ],
    },
    {
        path: '/record_responses/get_started/v2_start/:questionToken?',
        element: <V2Layout signup />,
    },
    {
        path: '/record_responses/:rrpfToken/:rrToken/v2_start/:questionToken?',
        element: <V2Layout />,
    },
    {
        path: '/record_responses/:rrpfToken/:rrToken/:questionToken?',
        element: <V2Layout />,
    },
    {
        path: '/record_responses/:rrpfToken',
        element: (
            <NavigateWithSearch
                to={{
                    pathname: 'terminal',
                }}
                replace
            />
        ),
    },
    {
        path: 'user_settings/*',
        element: (
            <LoginRequired>
                <Base>
                    <UserSettings />
                </Base>
            </LoginRequired>
        ),
    },
    {
        path: '/asyncCandidates/:candidateToken',
        element: (
            // TODO: this isnt really the right log in required
            <LoginRequired>
                <AsyncCandidatesHome />
            </LoginRequired>
        ),
        children: [
            { path: 'library', element: <AsyncCandidatesLibrary /> },
            { path: 'applications', element: <AsyncCandidatesApplications /> },
            { path: '*', loader: () => redirect(`./library${window.location.search}`) },
        ],
    },
    { path: '/createAccount', element: <CreateAccount /> },
    { path: '/confirm_account/:signedToken', element: <AsyncSelfSignupConfirmEmail /> },

    {
        // path: '/settings',
        element: (
            <LoginRequired product={'saas'}>
                <ActualApp />
            </LoginRequired>
        ),
        children: [
            {
                element: <ContentWrap />,
                children: [
                    {
                        path: '/settings',
                        element: (
                            <LoginRequired>
                                <ProtectedRoute menuOption="settings">
                                    <Settings />
                                </ProtectedRoute>
                            </LoginRequired>
                        ),
                        children: SettingsRoutes,
                    },
                ],
            },
        ],
    },

    {
        path: '/',
        errorElement: <ErrorPage />,
        element: (
            <LoginRequired product={'saas'}>
                <ActualApp />
            </LoginRequired>
        ),
        childrenElements: mainRoutes, // this prop is mad up so we can map it through to the createRoutesFromElements with the correct parentpath
    },
].map((r, idx) => ({
    children: r.childrenElements ? createRoutesFromElements(r.childrenElements, [idx]) : r.children,
    ...r,
    childrenElements: undefined,
}))

const router = createBrowserRouter(
    [
        {
            element: (
                <Suspense fallback={<SpinnerCentered />}>
                    <Outlet />
                </Suspense>
            ),

            children: allRoutes,
        },
    ],
    {
        future: {
            v7_fetcherPersist: true,
            v7_normalizeFormMethod: true,
            v7_relativeSplatPath: true,
            // v7_prependBasename: true,
        },
    },
)
function App() {
    axios.defaults.baseURL = import.meta.env.VITE_API_URL_BASE
    axios.defaults.withCredentials = true
    axios.defaults.withXSRFToken = axios.defaults.baseURL ? true : undefined // if the api base url is set (e.g on local, where the ports are different)
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = 'X-CSRFToken'
    return (
        <>
            <WTQueryClientProvider>
                <RouterProvider router={router} />
            </WTQueryClientProvider>
        </>
    )
}

export default App
